<template>
    <div class="max-w-7xl py-5 pb-24 mx-auto sm:px-6 lg:px-8 h-full flex flex-col">

        <div v-if="notFound === true" class="my-10 w-full flex flex-col justify-center content-center flex-wrap">
            <p class="font-sans text-white error-text text-center">404</p>
<!--            <p class="font-sans text-white text-4xl text-center">Community Not Found</p>-->
        </div>

        <div v-else-if="show">

            <div class="lg:flex flex-col justify-end min-w-0">
                <div class="flex mx-2 sm:mx-0 justify-between sm:justify-end ">
                    <div class="sm:ml-4 mt-2 flex-shrink-0">
                    <span class="inline-flex rounded-md shadow-sm">
                              <button @click="toggleSubscription" class="relative inline-flex items-center px-4 py-2 border text-sm leading-5 font-medium  rounded-md rounded-r-none text-white  lg:hover:bg-jiruto-zotSecondary hover:text-white focus:outline-none focus:shadow-outline-blue focus:border-jiruto-zotMain" :class="{'bg-jiruto-zotMain': isSubscribed}">
                                    <svg class="-ml-1 mr-2 h-5 w-5" :fill="isSubscribed ? '#e8b923' : 'currentColor'" viewBox="0 0 20 20">
                                        <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" fill-rule="evenodd"></path>                                    </svg>
                                  <svg fill="currentColor" viewBox="0 0 20 20"></svg>
                                {{isSubscribed ? "Unsubscribe" : "Subscribe"}}
                              </button>
                              <router-link :to="{name: 'subscribers'}" tag="button" class="relative inline-flex items-center px-4 py-2 border text-sm leading-5 font-medium rounded-md rounded-l-none text-white">
                                  {{ communityDetails.subsCount }}
                              </router-link>
                          </span>

                        <span class="ml-4  inline-flex rounded-md shadow-sm">
                              <button @click="toggleStar" class="relative inline-flex items-center px-4 py-2 border text-sm leading-5 font-medium  rounded-md rounded-r-none text-white  lg:hover:bg-jiruto-zotSecondary hover:text-white focus:outline-none focus:shadow-outline-blue focus:border-jiruto-zotMain" :class="{'bg-jiruto-zotMain': isStarred}">
                                    <svg class="-ml-1 mr-2 h-5 w-5" :fill="isStarred ? '#e8b923' : 'currentColor'" viewBox="0 0 20 20">
                                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                    </svg>
                                {{isStarred ? "Starred" : "Star"}}
                              </button>
                              <router-link :to="{name: 'learners'}" tag="button" class="relative inline-flex items-center px-4 py-2 border text-sm leading-5 font-medium rounded-md rounded-l-none text-white">
                                  {{ communityDetails.starsCount }}
                              </router-link>
                          </span>
                    </div>
                </div>


                <div class="mt-4 ml-2">
                    <h2 class="text-2xl font-bold leading-7 text-white sm:text-3xl sm:leading-9 sm:truncate">
                        {{ communityOwner + ": " + communityDetails.name }}
                    </h2>
                </div>
            </div>

            <div class="ml-2">
                <p class="text-gray-300 font-bold sm:leading-9 text-sm mt-4 ">
                    {{ communityDetails.description }}
                </p>
            </div>



            <div class="mt-4 mx-2">
                <div class="sm:hidden space-y-1">
                    <label id="listbox-label" class="block text-sm leading-5 font-medium text-gray-400">
                        Community Menu
                    </label>
                    <div  class="relative">
                        <span class="inline-block w-full rounded-md shadow-sm">
                            <button v-on-clickaway="closeMenu" @click="showCommunityMenu = !showCommunityMenu" type="button" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label" class="cursor-default relative w-full rounded-md border border-gray-300 bg-white pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                <span class="block truncate">
                                    {{ communityNames[smallMenuItem] }}
                                </span>
                                <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="none" stroke="currentColor">
                                        <path d="M7 7l3-3 3 3m0 6l-3 3-3-3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </span>
                            </button>
                        </span>

                        <!-- Select popover, show/hide based on select state. -->
                        <div v-if="showCommunityMenu" class="absolute mt-1 w-full rounded-md bg-white shadow-lg z-50">
                            <ul tabindex="-1" role="listbox" aria-labelledby="listbox-label" :aria-activedescendant="communityBoard" class="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5">
                                <!--
                                  Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.

                                  Highlighted: "text-white bg-indigo-600", Not Highlighted: "text-gray-900"
                                -->
                                <router-link exact :to="{name: 'communityBoard'}" v-slot="{navigate, isActive}">
                                    <li @click="navigate" id="communityBoard" role="option" class="text-gray-900 cursor-default select-none relative py-2 pl-8 pr-4 hover:text-white hover:bg-indigo-600 group">
                                        <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                                        <span class="font-normal block truncate">
                                            Community Board {{isActive}}
                                        </span>

                                        <!--
                                          Checkmark, only display for selected option.

                                          Highlighted: "text-white", Not Highlighted: "text-indigo-600"
                                        -->
                                        <span v-if="isActive" class="absolute inset-y-0 left-0 flex items-center pl-1.5 text-indigo-600 group-hover:text-white ">
                                            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                            </svg>
                                        </span>
                                    </li>
                                </router-link>
                                <router-link :to="{name: 'guideHome'}" v-slot="{navigate, isActive}">
                                    <li @click="navigate" id="guideHome" role="option" class="text-gray-900 cursor-default select-none relative py-2 pl-8 pr-4 hover:text-white hover:bg-indigo-600 group focus:bg-jiruto-zotSecondary">
                                        <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                                        <span class="font-normal block truncate">
                                            Community Guides
                                        </span>
                                        <span v-if="isActive" class="absolute inset-y-0 left-0 flex items-center pl-1.5 text-indigo-600 group-hover:text-white ">
                                            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                            </svg>
                                        </span>
                                    </li>
                                </router-link>
                                <router-link :to="{name: 'educators'}" v-slot="{navigate, isActive}">
                                    <li @click="navigate" id="educators" role="option" class="text-gray-900 cursor-default select-none relative py-2 pl-8 pr-4 hover:text-white hover:bg-indigo-600 group">
                                        <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                                        <span class="font-normal block truncate">
                                            Educators
                                        </span>
                                        <span v-if="isActive" class="absolute inset-y-0 left-0 flex items-center pl-1.5 text-indigo-600 group-hover:text-white ">
                                            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                            </svg>
                                        </span>
                                    </li>
                                </router-link>
                                <router-link v-if="isOwner" :to="{name: 'settings'}" v-slot="{navigate, isActive}">
                                    <li @click="navigate" id="settings" role="option" class="text-gray-900 cursor-default select-none relative py-2 pl-8 pr-4 hover:text-white hover:bg-indigo-600 group">
                                        <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                                        <span class="font-normal block truncate">
                                            Settings
                                        </span>
                                        <span v-if="isActive" class="absolute inset-y-0 left-0 flex items-center pl-1.5 text-indigo-600 group-hover:text-white ">
                                            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                            </svg>
                                        </span>
                                    </li>
                                </router-link>

                            </ul>
                        </div>
                    </div>
                </div>

            </div>


            <div class="mt-4 mx-2">
<!--                <div class="sm:hidden">-->
<!--                    <select v-model="routeName" class="form-select block w-full">-->
<!--                        <option value="communityBoard">Community Board</option>-->
<!--                        <option value="guideHome">Guide</option>-->
<!--                        <option value="educators">Educators</option>-->
<!--                        <option value="settings" v-if="isOwner">Settings</option>-->
<!--                    </select>-->
<!--                </div>-->
                <div class="hidden sm:block mx-2">
                    <div class="border-gray-200">
                        <nav class="-mb-px flex justify-center">
<!--                            <router-link v-if="integrations.board === 'active'" tag="a" :to="{name: 'communityBoard'}" exact class="w-1/3 py-2 px-1 text-center bg-white rounded-md mr-2  font-medium text-md leading-5 text-gray-800 hover:text-indigo-600 hover:bg-purple-200 focus:outline-none focus:text-gray-700 focus:bg-purple-400" :class="{'w-1/4' : isOwner}">-->
<!--                                Boards-->
<!--                            </router-link>-->
                            <router-link v-if="integrationStatus('boards') === 'active'"
                                         tag="a" :to="{name: 'allBoards'}"
                                         class="w-1/3 py-2 px-1 text-center bg-white rounded-md mr-2 font-medium text-md leading-5 text-gray-800 hover:text-indigo-600 hover:bg-purple-200 focus:outline-none focus:text-gray-700 focus:bg-purple-400"
                                         :class="{'w-1/4' : isOwner}">
                                Boards
                            </router-link>
                            <router-link v-if="integrationStatus('forum') === 'active'"  tag="a" :to="{name: 'forumHome'}"  class="w-1/3 py-2 px-1 text-center mr-2 bg-white rounded-md font-medium text-md leading-5 text-gray-800 hover:text-indigo-600 hover:border-gray-400 hover:bg-purple-200 focus:outline-none focus:text-gray-700 focus:border-gray-300 focus:bg-purple-400" :class="{'w-1/4' : isOwner}">
                                Discussions
                            </router-link>
                            <router-link v-if="integrationStatus('group-sessions') === 'active'" tag="a" :to="{name: 'communityGroupSessions'}"  class="w-1/3 py-2 px-1 text-center mr-2 bg-white rounded-md font-medium text-md leading-5 text-gray-800 hover:text-indigo-600 hover:border-gray-400 hover:bg-purple-200 focus:outline-none focus:text-gray-700 focus:border-gray-300 focus:bg-purple-400" :class="{'w-1/4' : isOwner}">
                                Group Sessions
                            </router-link>
<!--                            <router-link tag="a" :to="{name: 'guideHome'}"  class="w-1/3 py-2 px-1 text-center mr-2  bg-white rounded-md font-medium text-md leading-5 text-gray-800 hover:text-indigo-600 hover:border-gray-400 hover:bg-purple-200 focus:outline-none focus:text-gray-700 focus:border-gray-300 focus:bg-purple-400" :class="{'w-1/4' : isOwner}">-->
<!--                                Blog Space-->
<!--                            </router-link>-->
<!--                            <router-link tag="a" :to="{name: 'educators'}"  class="w-1/3 py-2 px-1 text-center mr-2 bg-white rounded-md font-medium text-md leading-5 text-gray-800 hover:text-indigo-600 hover:border-gray-400 hover:bg-purple-200 focus:outline-none focus:text-gray-700 focus:border-gray-300 focus:bg-purple-400" :class="{'w-1/4' : isOwner}">-->
<!--                                Community Educators-->
<!--                            </router-link>-->
                            <router-link v-if="isOwner" tag="a" :to="{name: 'communitySettingsMain'}"  class="w-1/4 py-2 px-1 text-center bg-white rounded-md font-medium text-md leading-5 text-gray-800 hover:text-indigo-600 hover:bg-purple-200 hover:border-gray-400 focus:outline-none focus:text-gray-700 focus:border-gray-300 focus:bg-purple-400"  :class="{'w-1/4' : isOwner}">
                                Community Settings
                            </router-link>
                        </nav>
                    </div>
                </div>
            </div>

            <div class="h-8 border-b-2"/>

            <router-view :communityID="communityDetails.JID" @viewLoading="viewLoaded=false" class="mx-2" @viewLoaded="viewLoaded=true" v-bind="{communityOwner: communityOwner, communityName: communityName}"/>
        </div>

        <div v-if="viewLoaded" class="mt-10">
            <div class="max-w-4xl mx-auto text-center">
                <h2 class="text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10">
                    Do you find this learning community useful?
                </h2>
                <p class="mt-2 text-xl leading-7 text-indigo-200 sm:mt-2">
                    Help other learners and share it:
                </p>
            </div>

            <div class="mt-5">
                <div class="max-w-screen-xl mx-auto py-1 px-2 sm:px-4 lg:px-4">
                    <div class="grid grid-cols-2 gap-2 md:grid-cols-6 lg:grid-cols-6">
                        <div v-for="(network) in networks" :key="network.network" :style="{backgroundColor: network.color}" class="col-span-1 flex justify-center items-center md:col-span-2 lg:col-span-1">
                            <ShareNetwork
                                    class=" py-5 cursor-pointer"
                                    @click.native="this.$analytics.logEvent('community-shared')"
                                    :network="network.network"
                                    :style="{backgroundColor: network.color}"
                                    :url="sharing.url"
                                    :title="sharing.title"
                                    :description="sharing.description"
                                    :quote="sharing.quote"
                                    :hashtags="sharing.hashtags"
                                    :twitterUser="sharing.twitterUser"
                            >
                                <div class="flex items-center flex-col" >
                                    <dd class="text-xl sm:text-3xl leading-none font-extrabold text-white ">
                                        <span>{{ network.name }}</span>
                                    </dd>
                                </div>
                            </ShareNetwork>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters, mapState, mapActions} from 'vuex'
    import {directive as onClickaway} from 'vue-clickaway'
    import * as fb from '../../firebaseConfig'
    import * as axios from '../../axios-auth'
    export default {

        name: "JirutoCommunity",
        directives: {
            onClickaway
        },
        props: {
            communityOwner: {
                type: String,
                required: true,
            },
            communityName: {
                type: String,
                required: true,
            },
        },
        metaInfo(){
            if(this.communityDetails === null) {
                return
            }
            const title = "Jiruto Community: " + this.communityDetails.name + " by @" + this.communityOwner

            return{
                // title: "Jiruto Community: " + this.communityName + " by @" + this.communityOwner,
                meta: [
                    // {vmid: 'og:description', property: "description", name:'description', content: this.communityDetails.description},
                    {vmid: 'og:title', property: "og:title", name:'ogTitle', content: title},
                    // {vmid: 'og:url', property: "og:url", name:'ogUrl', content: "https://Jiruto.com"},
                    {vmid: 'og:description', property: "og:description", name:'ogDescription', content: this.communityDetails.description},

                    {vmid: 'twitter:description', property: "description", name:'description', content: this.communityDetails.description},
                    {vmid: 'twitter:title', property: "twitter:title", name:'twitterTitle', content: title},
                    // {vmid: 'twitter:url', property: "twitter:url", name:'twitterUrl', content: "https://Jiruto.com"},
                    {vmid: 'twitter:description', property: "twitter:description", name:'twitterUrl', content: this.communityDetails.description},
                ]
            }
        },
        data() {
            return {
                smallMenuItem: "",
                menuItemShowing: "",
                showCommunityMenu: false,
                routeName: "communityBoard",
                show: false,
                notFound: null,
                isStarred: false,
                // isSubscribed: false,
                viewLoaded: false,
                // showAlertModal: false,

                sharing: {
                    url: `https://jiruto.com/${this.communityOwner}/${this.communityName}`,
                    title: `Jiruto: ${this.communityName}`,
                    description: this.communityDescription,
                    quote: '"He who cares for days- Sows wheat\n' +
                        'He who cares for years- plants trees\n' +
                        'He who cares for generations - educates people"\n' +
                        '                                     (Janusz Korczak)',
                    // quote: 'We are storytelling creatures, and as children we acquire language to tell those stories that we have inside us. - Jerome Bruner',
                    hashtags: 'openEd,learningcommunity,jiruto,sociallearning',
                    twitterUser: 'JirutoLearn'
                },
                networks: [
                    { network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook-f', color: '#1877f2' },
                    { network: 'twitter', name: 'Twitter', icon: 'fab fah fa-lg fa-twitter', color: '#1da1f2' },
                    { network: 'reddit', name: 'Reddit', icon: 'fab fah fa-lg fa-reddit-alien', color: '#ff4500' },
                    { network: 'linkedin', name: 'LinkedIn', icon: ['fab', 'fah', 'fa-lg', 'fa-linkedin'], color: '#007bb5' },
                    { network: 'whatsapp', name: 'Whatsapp', icon: 'fab fah fa-lg fa-whatsapp', color: '#25d366' },
                    { network: 'hackernews', name: 'HackerNews', icon: 'fab fah fa-lg fa-hacker-news', color: '#ff4000' },
                ],
                communityNames: {
                    communityBoard: "Community Board",
                    guideHome: "Guides",
                    educators: "Educators",
                    discussion: "Discussion",
                    settings: "Settings"
                }
            }
        },
        created(){
            this.$store.dispatch("setCommunityOwner", this.communityOwner)
            this.$store.dispatch("setCommunityName", this.communityName)
            this.smallMenuItem = this.$router.currentRoute.name
            this.fetchCommunity()
            if(fb.auth.currentUser){
                this.getIsStarred()
                this.getIsSubscribed()
            }
            this.fetchIntegrations()
        },
        beforeDestroy(){
            this.$store.dispatch("exitCommunity")
        },
        methods: {
            ...mapActions(['subscribe','getIsSubscribed', 'unSubscribe', 'getIntegrationStatus']),
            integrationStatus(integrationName){
                if (this.integrations === null) {
                    return "unintegrated"
                }
                for (let integration of this.integrations) {
                    if (integrationName === integration.name) {
                        return integration.status
                    }
                }
            },
            fetchIntegrations(){
                axios.jigo.get(`/v2/community/getIntegrations/${this.communityOwner}/${this.communityName}`, {validateStatus: (status) => {
                        return status < 500
                    }})
                .then((resp) => {
                    if(resp.status === 200) {
                        console.log("Fetch integration", resp.data)
                        this.$store.dispatch("setIntegrations", resp.data)
                    } else {
                        console.error("ERROR: unwanted status returned", resp.status)
                    }
                })
                .catch((err) => {
                    console.error("ERROR: fetchIntegrations FAILED", err)
                })
            },
            fetchCommunity(){
                axios.jigo.get(`/v2/community/getCommunity/${this.communityOwner}/${this.communityName}`, {validateStatus: (status)=> {
                        return status < 500
                    }})
                .then(resp => {
                    if (resp.status === 200) {
                        this.communityDetails = resp.data
                        this.show = true
                    } else if (resp.status === 404){
                        this.notFound = true
                    }

                }).catch( (err) => {
                    console.error("An error occurred trying to fetch the community", err)
                })
            },
            closeMenu(){
                this.showCommunityMenu = false
            },
            changeRoute(name){
                console.log(name)
            },
            formattedDate(dateString){
                let date = new Date(dateString)
                return date.toLocaleString()
            },
            toggleStar(){
                if(fb.auth.currentUser){
                    this.isStarred ? this.unStar() : this.star()
                } else {
                    this.$router.push("/login")
                }
            },
            star(){
                this.isStarred = true
                this.communityDetails.starsCount = this.communityDetails.starsCount + 1
                axios.jigo.put(`/v2/user/star/${this.communityOwner}/${this.communityName}`, {validateStatus: (status) => {
                        return status < 500
                    }})
                    .then((resp) => {
                        if (resp.status !== 201) {
                            this.isStarred = false
                            this.communityDetails.starsCount = this.communityDetails.starsCount - 1
                        }
                    })
                    .catch((err) => {
                        this.isStarred = false
                        this.communityDetails.starsCount = this.communityDetails.starsCount - 1
                        console.error("An error occurred while trying to immerse the user: ", err)
                    })
            },
            unStar(){
                this.isStarred = false
                this.communityDetails.starsCount = this.communityDetails.starsCount - 1
                axios.jigo.delete(`/v2/user/star/${this.communityOwner}/${this.communityName}`, {validateStatus: (status) => {
                        return status < 500
                    }})
                    .then((resp) => {
                        if (resp.status !== 204) {
                            this.isStarred = true
                            this.communityDetails.starsCount = this.communityDetails.starsCount + 1
                        }
                    })
                    .catch((err) => {
                        this.isStarred = true
                        this.communityDetails.starsCount = this.communityDetails.starsCount + 1
                        console.error("An error occurred while trying to immerse the user: ", err)
                    })
            },
            toggleSubscription(){
                if(fb.auth.currentUser){
                    this.isSubscribed ? this.unSubscribe() : this.subscribe()
                }else {
                    this.$router.push("/login")
                }

            },
            subscribe(){
                this.isSubscribed = true;
                this.communityDetails.subsCount = this.communityDetails.subsCount + 1;
                axios.jigo.put(`/v2/user/subscription/${this.communityOwner}/${this.communityName}`, {validateStatus: (status) => {
                        return status < 500
                    }})
                    .then((resp) => {
                        if (resp.status !== 201) {
                            this.isSubscribed = false;
                            this.communityDetails.subsCount = this.communityDetails.subsCount - 1;
                        }
                    })
                    .catch((err) => {
                        this.isSubscribed = false;
                        this.communityDetails.subsCount = this.communityDetails.subsCount - 1;
                        console.error("An error occurred while trying to subscribe the user: ", err)
                    })
            },
            unSubscribe(){
                this.isSubscribed = false;
                this.communityDetails.subsCount = this.communityDetails.subsCount - 1;
                axios.jigo.delete(`/v2/user/subscription/${this.communityOwner}/${this.communityName}`, {validateStatus: (status) => {
                        return status < 500
                    }})
                    .then((resp) => {
                        if (resp.status !== 204) {
                            this.isSubscribed = true;
                            this.communityDetails.subsCount = this.communityDetails.subsCount + 1
                        }
                    })
                    .catch((err) => {
                        this.isSubscribed = true;
                        this.communityDetails.subsCount = this.communityDetails.subsCount + 1
                        console.error("An error occurred while trying to unsubscribe the user: ", err)
                    })
            },
            getIsStarred(){
                axios.jigo.get(`/v2/user/star/${this.communityOwner}/${this.communityName}`, {validateStatus: (status) => {
                    return status < 500
                    }})
                    .then((resp) => {
                        if (resp.status === 200){
                            this.isStarred = resp.data
                        } else {
                            this.isStarred = false
                        }
                    })
                    .catch((err) => {
                        console.error("An error occurred while trying to find if the user is immersed: ", err)
                    })
            },
            loaderCanceled(){
                console.error("Loader Canceled")
            },

        },
        computed: {
            ...mapGetters(['getCommunities', 'getUsername']),
            ...mapState({
                integrations: state => state.community.integrations,
            }),
            communityDetails: {
                get: function() { return this.$store.state.community.communityDetails },
                set: function(newValue) { this.$store.commit("setCommunityDetails", newValue)}
            },
            isSubscribed: {
                get: function() { return this.$store.state.community.isSubscribed },
                set: function(newValue) { this.$store.commit("setIsSubscribed", newValue)}
            },
            fullCommunityName() {
                return `${this.communityOwner}/${this.communityName}`
            },
            hasAccess(){
                return (this.getCommunities.includes(this.fullCommunityName) || this.isOwner)
            },
            isOwner(){
                return (this.getUsername === this.communityOwner)
            },
            communityTitle(){
                return this.communityDetails.name
            },
            communityDescription(){
                return this.communityDetails.description
            },
            menuCommunityItem(){
                return this.$router.currentRoute.name
            }
        },
        watch: {
            communityOwner(newVal){
                this.$store.commit("setCommunityOwner", newVal)
            },
            communityName(newVal){
                this.$store.commit("setCommunityName", newVal)
            },
            routeName(newVal){
                this.$router.push({name: newVal})
            },
            $route(){
                this.smallMenuItem = this.$router.currentRoute.name
            }
        },


    }
</script>

<style scoped>
    .router-link-active{
        @apply bg-jiruto-zotSecondary text-white outline-none
    }
    .smallActive {
        @apply text-black font-semibold
    }


    .error-text {
        font-size: 130px;
    }

    @media (min-width: 768px) {
        .error-text {
            font-size: 220px;
        }
    }
</style>
